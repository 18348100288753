<template>
  <!-- 行驶数据 -->
  <div ref="container" class="chart" :class="{
    [$style.chart]: true,
    [$style.large]: large
  }"></div>
</template>

<style module lang="scss">
.chart {
  height: 6rem;

  &.large {
    height: 15rem;
  }
}
</style>

<script>
import { Chart } from "@antv/g2";

const { remUnit = 20 } = window;

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false
    },
    vData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      chart: undefined
    };
  },
  mounted() {
    const { vData } = this;

    this.initChart(vData);
  },
  watch: {
    vData(val) {
      this.initChart(val);
    }
  },
  methods: {
    initChart(data) {
      const { container } = this.$refs;
      const { large } = this;
      const legendCfg = {
        position: 'right',
        marker: {
          symbol: 'circle',
        },
        offsetX: (large ? -8.8 : -3.8) * remUnit,
        flipPage: false,
        // itemSpacing: 0.2 * remUnit,
        itemName: {
          style: {
            fontSize: (large ? 0.8 : 0.6) * remUnit,
            fill: '#888FCD',
          },
          formatter(text) {
            return text;
          },
        },
        itemWidth: (large ? 8 : 6) * remUnit,
        itemValue: {
          style: {
            fontSize: (large ? 0.8 : 0.6) * remUnit,
            fill: '#ffffff',
          },
          alignRight: true,
          formatter(text, item, idx) {
            return data[idx].percent + '%';
          },
        },
      };
      let { chart } = this;

      if (!data || data.length === 0) return;

      if (chart) {
        chart.legend(legendCfg);

        chart.changeData(data);

        return;
      }
      
      chart = new Chart({
        container,
        autoFit: true,
        padding: [0, (large ? 18 : 10)* remUnit, 0, 0],
      });

      chart.scale('percent', {
        formatter: (val) => {
          return val + '%';
        },
      });
      chart.coordinate('theta', {
        radius: 0.75,
        innerRadius: 0.6,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart
        .interval()
        .adjust('stack')
        .position('percent')
        // .color("label");
        .color("label", ['#00F6E5', '#5CBC54', '#EE6F49', '#9D54FF', "#BAE7FF"]);
      
      // 图例
      chart.legend(legendCfg);

      chart.interaction("element-active");
      chart.data(data);
      chart.render();
      this.chart = chart;
    },
  },
};
</script>